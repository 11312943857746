import React from 'react';
import * as S from './HeroElements';
import Video from '../../assets/videos/hero-bg.mp4';
import { TypeFormBtn } from '../common/TypeFormButton';

const HeroSection = () => {
  return (
    <>
      <S.HeroContainer>
        <S.HeroDarkBg />
        <S.HeroBackground
          dangerouslySetInnerHTML={{
            __html: `
            <Video
              autoPlay
              playsinline 
              loop
              muted
              src=${Video}
              type="video/mp4"
              style="
                width: 100%;
                height: 100%;
                -o-object-fit: cover;
                object-fit: cover;
                background: #232a34;
              "
            />
            `,
          }}
        ></S.HeroBackground>
        <S.HeroContent>
          <S.HeroH1>Sell more, increase leads, and be found online</S.HeroH1>
          <S.HeroP>
            While you focus on your product or service we will bring incredible
            online experiences to your customers
          </S.HeroP>
          <S.HeroBtnWrapper>
            <TypeFormBtn id="bQ9TTBQG">Get Started</TypeFormBtn>
          </S.HeroBtnWrapper>
        </S.HeroContent>
      </S.HeroContainer>
    </>
  );
};

export default HeroSection;
