export const homeSectionObjOne = {
  bgColor: '',
  id: 'our-process',
  imgStart: false,
  topLineTextColor: '#04BFAD',
  topLine: '',
  headlineTextColor: '',
  headline: 'Our Process',
  descriptionTextColor: '',
  description: 'Takes 3 steps!',
  btnPrimary: true,
  btnLabel: 'Get Started',
  img: require('../../assets/images/team.svg').default,
  alt: 'Team',
  primary: true,
  dark: true,
};
