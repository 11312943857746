import React from 'react';
import * as S from './FooterElements';

const Footer = () => {
  return (
    <S.FooterContainer id="contact">
      <S.FooterWrap>
        <S.FooterH1>Contact</S.FooterH1>
        <S.FooterLinksContainer>
          <S.FooterLinksWrapper>
            <S.FooterLinkItems>
              <S.FooterLinkTitle>General Inquiries</S.FooterLinkTitle>
              {/* <S.FooterLink to="/"> */}
              <S.FooterName>Sebastian M.</S.FooterName>
              <S.FooterEmail>sm@allwedoisweb.com</S.FooterEmail>
              {/* <S.FooterNumber>256-419-6428</S.FooterNumber> */}
              {/* </S.FooterLink> */}
            </S.FooterLinkItems>
            <S.FooterLinkItems>
              <S.FooterLinkTitle>Technical Inquiries</S.FooterLinkTitle>
              {/* <S.FooterLink to="/"> */}
              <S.FooterName>Jose M.</S.FooterName>
              <S.FooterEmail>jm@allwedoisweb.com</S.FooterEmail>
              {/* <S.FooterNumber>256-365-4571</S.FooterNumber> */}
              {/* jm@allwedoisweb.com 256-365-4571 */}
              {/* </S.FooterLink> */}
            </S.FooterLinkItems>
          </S.FooterLinksWrapper>
        </S.FooterLinksContainer>
      </S.FooterWrap>
    </S.FooterContainer>
  );
};

export default Footer;
