import styled from 'styled-components';
import { PopupButton } from '@typeform/embed-react';

export const TypeFormBtn = styled(PopupButton)`
  border-radius: 50px;
  background: #fff;
  white-space: nowrap;
  padding: 10px 22px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: linear-gradient(90deg, #e3ffe7 0%, #d9e7ff 100%);
  }
`;
