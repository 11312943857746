import React, { useState, useEffect } from 'react';
import * as S from './NavbarElements';
import { FaBars } from 'react-icons/fa';
import awdiwLogo from '../../assets/images/awdiw-logo.svg';
import { animateScroll as scroll } from 'react-scroll';
import { TypeFormBtn } from '../common/TypeFormButton';

const Navbar = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 1) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <>
      <S.Nav scrollNav={scrollNav}>
        <S.NavbarContainer>
          <S.NavLogo to="/" onClick={toggleHome}>
            <S.LogoIcon src={awdiwLogo} alt="AWDIW Logo" />
          </S.NavLogo>
          <S.MobileIcon onClick={toggle}>
            <FaBars />
          </S.MobileIcon>
          <S.NavMenu>
            <S.NavItem>
              <S.NavLinks
                to="our-process"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-100}
                activeClass="active"
              >
                Our Process
              </S.NavLinks>
            </S.NavItem>
            <S.NavItem>
              <S.NavLinks
                to="services"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-100}
              >
                Services
              </S.NavLinks>
            </S.NavItem>
            <S.NavItem>
              <S.NavLinks
                to="contact"
                smooth={true}
                duration={500}
                spy={true}
                exact="true"
                offset={-100}
              >
                Contact
              </S.NavLinks>
            </S.NavItem>
          </S.NavMenu>
          <S.NavBtn>
            <TypeFormBtn id="bQ9TTBQG">Get Started</TypeFormBtn>
          </S.NavBtn>
        </S.NavbarContainer>
      </S.Nav>
    </>
  );
};

export default Navbar;
