import React from 'react';
import * as S from './ServicesElements';
import { services } from './Data';

const Services = () => {
  return (
    <S.ServicesContainer id="services">
      <S.ServicesH1>Our Services</S.ServicesH1>
      <S.ServiceWrapper>
        {services.map((service) => (
          <S.ServicesCard id={service.id}>
            <S.ServicesIcon src={service.img} alt="Service" />
            <S.ServicesH2>{service.serviceHeader}</S.ServicesH2>
            <S.ServiceP>{service.serviceDescription}</S.ServiceP>
          </S.ServicesCard>
        ))}
      </S.ServiceWrapper>
    </S.ServicesContainer>
  );
};

export default Services;
