import React from 'react';
import * as S from './SidebarElements';
import { TypeFormBtn } from '../common/TypeFormButton';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <S.SidebarContainer isOpen={isOpen} onClick={toggle}>
      <S.Icon onClick={toggle}>
        <S.CloseIcon onClick={toggle} />
      </S.Icon>
      <S.SidebarWrapper>
        <S.SidebarMenu>
          <S.SidebarLink
            to="our-process"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-100}
            onClick={toggle}
          >
            Our Process
          </S.SidebarLink>
          <S.SidebarLink
            to="services"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-100}
            onClick={toggle}
          >
            Services
          </S.SidebarLink>
          <S.SidebarLink
            to="contact"
            smooth={true}
            duration={500}
            spy={true}
            exact="true"
            offset={-100}
            onClick={toggle}
          >
            Contact
          </S.SidebarLink>
        </S.SidebarMenu>
        <S.SideBtnWrapper>
          <TypeFormBtn id="bQ9TTBQG">Get Started</TypeFormBtn>
        </S.SideBtnWrapper>
      </S.SidebarWrapper>
    </S.SidebarContainer>
  );
};

export default Sidebar;
