import React from 'react';
import { TypeFormBtn } from '../common/TypeFormButton';
import * as S from './InfoSectionElements';

const InfoSection = ({
  bgColor,
  id,
  topLine,
  topLineTextColor,
  headline,
  headlineTextColor,
  descriptionTextColor,
  description,
  btnLabel,
  imgStart,
  img,
  alt,
}) => (
  <S.InfoContainer bgColor={bgColor} id={id}>
    <S.InfoWrapper>
      <S.InfoRow imgStart={imgStart}>
        <S.Column1>
          <S.TextWrapper>
            <S.TopLine topLineTextColor={topLineTextColor}>{topLine}</S.TopLine>
            <S.Heading headlineTextColor={headlineTextColor}>
              {headline}
            </S.Heading>
            <S.Subtitle descriptionTextColor={descriptionTextColor}>
              {description}
            </S.Subtitle>
            <S.StepsList>
              <S.LiItemWrapper>
                <S.LiNum>1</S.LiNum>
                <S.ListProcessItem>Creative Brief</S.ListProcessItem>
              </S.LiItemWrapper>
              <S.LiItemWrapper>
                <S.LiNum>2</S.LiNum>
                <S.ListProcessItem>Design and Development</S.ListProcessItem>
              </S.LiItemWrapper>
              <S.LiItemWrapper>
                <S.LiNum>3</S.LiNum>
                <S.ListProcessItem>Project Hand-Off</S.ListProcessItem>
              </S.LiItemWrapper>
            </S.StepsList>
            <S.BtnWrap>
              <TypeFormBtn id="bQ9TTBQG">{btnLabel}</TypeFormBtn>
            </S.BtnWrap>
          </S.TextWrapper>
        </S.Column1>
        <S.Colum2>
          <S.ImgWrap>
            <S.Img src={img} alt={alt} />
          </S.ImgWrap>
        </S.Colum2>
      </S.InfoRow>
    </S.InfoWrapper>
  </S.InfoContainer>
);

export default InfoSection;
