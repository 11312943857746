export const services = [
  {
    id: 'service1',
    img: require('../../assets/images/service-design.svg').default,
    serviceHeader: 'Web Design',
    serviceDescription: 'We taylor-made your online look',
  },
  {
    id: 'service2',
    img: require('../../assets/images/service-website.svg').default,
    serviceHeader: 'Web Development',
    serviceDescription:
      'We build your site with the latest technologies to maximise your online presence',
  },
  {
    id: 'service3',
    img: require('../../assets/images/service-publish.svg').default,
    serviceHeader: 'Consulting',
    serviceDescription:
      'We guide you to make the best business decision for your online needs',
  },
];
